import AuthLayout from "@/layouts/auth-layout";
import "./styles.scss";
import { Button, Icon } from "@/components";
import SignInForm from "./form";

export const SignIn = () => {
  return (
    <AuthLayout>
      <div className="signin">
        <Button variant="neutral" className="go-back">
          <Icon name="left_arrow" />
          Back
        </Button>

        <div className="headings">
          <h1>Sign In</h1>
          <h3>
            To sign in, please type in the email address & password linked to
            your Pluritongues account.
          </h3>
        </div>

        <SignInForm />
        <h6>
          If you aren’t registered on Pluritongues, download the app{" "}
          <a href="#" target="_blank" rel="noopener noreferrer">
            here
          </a>{" "}
          and sign up in a few minutes.
        </h6>
      </div>
    </AuthLayout>
  );
};
