import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import Icon from "../Icon";
import "./index.scss";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: "primary" | "secondary" | "neutral";
  isLoading?: boolean;
}

export default function Button({
  children,
  variant,
  className,
  isLoading,
  ...props
}: ButtonProps) {
  return (
    <button
      className={`custom-btn ${className}`}
      {...props}
      data-variant={variant}
    >
      {isLoading ? <Icon name="spin" /> : children}
    </button>
  );
}
