import { Icon } from "@/components"
import "./header.scss"
import Icons from "@/components/Icon/icons"

export default function Header(){
    return (
        <div className="header">
            <div className="greeting">
              Hi, Okonkwo Kene
            </div>

            <div className="description">
                <Icon name="message" />
                <div className="language-and-arrow">
                    <div>Igbo</div>
                    <Icon name="downArrow" />
                </div>
            </div>

            <div className="games">
               <div className="coin">
                <div className="circle"></div>
                <div>500</div>
               </div>

               <div className="egg">
                 <Icon name="egg" />
                 <div>5</div>
               </div>

               <div className="star">
                 <Icon name="star" />
                 <div>5</div>
               </div>
            </div>
        </div>
    )
}