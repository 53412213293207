import { activities } from "@/mock/activties";
import { ProgressBar, Icon, Button } from "@/components";

import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { Question } from "./Question";

export default function Activity() {
  const [questionIndex] = useState(0);
  const activity_id = useParams()["activity_id"];
  const activity = activities.find((a) => a.id === activity_id);
  const complettion_percent = activity
    ? activity.lesson_progress / activity.questions.length
    : 0;
  const currentQuestion = useMemo(() => {
    const questions = activity?.questions ?? [];
    return questions[questionIndex];
  }, [questionIndex, activity]);

  return (
    <div className="single_activity--container">
      <div className="top">
        <Button variant="neutral">
          <Icon name="close" />
        </Button>
        <ProgressBar percent={complettion_percent} />
      </div>
      <Question question={currentQuestion} />
    </div>
  );
}
