import { Activity } from "@/types/activity";

export const activities: Activity[] = [
  {
    id: "1",
    title: "Level A1 (Beginner) ",
    level: "A1",
    description:
      "At this level, learners focus on fundamental vocabulary, basic grammar structures, and essential communication skills.",
    image: "/activity.png",
    questions: [
      {
        id: "1",
        type: "speak_select",
        audio: "https://lams-audio-assets.s3.amazonaws.com/0001.mp3",
        answer: ["1"],
        options: [
          {
            id: "1",
            image: "/nwoke.png",
            text: "Nwoke",
            is_answer: true,
          },
          {
            id: "2",
            image: "/nwanyi.png",
            text: "Nwanyi",
            is_answer: false,
          },
          {
            id: "3",
            image: "/odogwu.png",
            text: "Odogwu",
            is_answer: false,
          },
          {
            id: "4",
            image: "/odogwuress.png",
            text: "Odogwuress",
            is_answer: false,
          },
        ],
      },
    ],
    lesson_progress: 0,
  },
];
