import "./styles.scss";

export function ProgressBar({
  percent,
  height = 24,
}: {
  percent: number | `${number}%`;
  height?: number;
}) {
  const width = typeof percent === "number" ? `${percent * 100}%` : percent;
  return (
    <div className="progress_bar--container" style={{ height }}>
      <div className="progress" style={{ width, minWidth: height }}></div>
    </div>
  );
}
