import "@/styles/global.scss";
import "./App.css";
import { Root } from "@/routes/Root";

function App() {
  return (
    <>
      
      <Root />
    </>
  );
}

export default App;
