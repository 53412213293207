import { ReactNode } from "react";
import './styles.scss'
import SideBar from "./side-bar/SideBar";
import Header from "./header/Header";

export default function DashboardLayout({ children }: { children: ReactNode }) {
  return (
    <div className="dashboard_layout--container">
      <SideBar />
      <div>
        <Header />
        <div>{children}</div>
      </div>
    </div>
  );
}
