import "./styles.scss";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  error?: { message: string } | null;
  label?: React.ReactElement | string;
}

const TextInput = ({
  className,
  label,
  error,
  containerClassName,
  ...props
}: InputProps) => {
  return (
    <div className={`input_container ${containerClassName}`}>
      {label && (
        <label htmlFor={props?.id} className={`input_label`}>
          {label}
          {props.required && "*"}
        </label>
      )}
      <input
        {...props}
        type={props.type || "text"}
        className={`input ${className}`}
      />
      {error?.message && (
        <div className="error">
          <p className="">{error.message}</p>
        </div>
      )}
    </div>
  );
};

export default TextInput;
