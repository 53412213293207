import "./styles.scss";
import { Button, Icon } from "@/components";

export function Verdict({
  open,
  is_answer,
  answer,
  onClose,
}: {
  open: boolean;
  is_answer: boolean;
  answer: string;
  onClose: () => void;
}) {
  if (!open) return null;
  return (
    <div
      className="verdict_container"
      data-is-answer={String(is_answer)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="inner_container">
        <div>
          {is_answer ? (
            <div className="is_answer">
              <Icon name="correct" />
              Correct
            </div>
          ) : (
            <div className="is_answer">
              <Icon name="wrong" />
              Wrong
            </div>
          )}

          <div className="answer">Answer = {answer}</div>
        </div>
        <Button variant="secondary" onClick={onClose}>
          Got it
        </Button>
      </div>
    </div>
  );
}
