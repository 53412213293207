import { ReactNode } from "react";
import "./styles.scss";

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <div className="auth-layout--container">
      <div className="page">{children}</div>
      <div></div>
    </div>
  );
}
