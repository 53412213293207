import { PasswordInput, TextInput } from "@/components";

export default function SignInForm() {
  return (
    <form className="signin_form">
      <TextInput label={"email"} type="email" placeholder="Email" />
      <PasswordInput label={"password"} />
    </form>
  );
}
