import type { Activity, SpeakAndSelectLessonQuestion } from "@/types/activity";
import "./styles.scss";
import { Button, Icon } from "@/components";
import { useState, useRef, useMemo } from "react";
import { Verdict } from "../Verdict";

type QuestionType = Activity["questions"][number];

export const Question = ({ question }: { question: QuestionType }) => {
  if (question.type === "speak_select") {
    return <SelectWhatYouHearQuestion question={question} />;
  }
  return <>Unknown question type</>;
};

const SelectWhatYouHearQuestion = ({
  question,
}: {
  question: SpeakAndSelectLessonQuestion;
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const answerOption = useMemo(
    () => question.options.find((o) => question.answer.includes(o.id)),
    [question]
  );

  const playAudio = () => {
    if (isAudioPlaying) audioRef.current?.pause();
    else audioRef.current?.play();
  };

  const handleClickOption = (optionId: string) => {
    setCanCheck(false);
    setSelectedOptionId((prev) => (prev === optionId ? null : optionId));
  };

  const handleCheck = () => setCanCheck(true);
  return (
    <div className="question_container" data-type={question.type}>
      <div className="top">
        <h1>Select The Correct Image</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <Button
          variant="neutral"
          className="audio_container"
          onClick={playAudio}
        >
          <Icon name="audio" />
          <audio
            src={question.audio}
            ref={audioRef}
            onPlay={() => setIsAudioPlaying(true)}
          ></audio>
        </Button>
        <Button variant="neutral" className="cantlisten">
          CAN'T LISTEN NOW
        </Button>
      </div>
      <div className="options">
        {question.options.map((option) => (
          <Button
            variant="neutral"
            key={option.id}
            className="option"
            data-selected={String(option.id === selectedOptionId)}
            data-correct={String(
              canCheck && option.id === selectedOptionId && option.is_answer
            )}
            data-wrong={String(
              canCheck && option.id === selectedOptionId && !option.is_answer
            )}
            onClick={() => handleClickOption(option.id)}
          >
            <img src={option.image} alt="option" />
            <p>{option.text}</p>
          </Button>
        ))}
      </div>

      <Button
        disabled={selectedOptionId === null}
        variant="primary"
        className="check-btn"
        onClick={handleCheck}
      >
        Check
      </Button>
      <Verdict
        is_answer={answerOption?.id === selectedOptionId}
        answer={answerOption?.text ?? "No answer"}
        open={canCheck}
        onClose={() => setCanCheck(false)}
      />
    </div>
  );
};
