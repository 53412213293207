import DashboardLayout from "@/layouts/dashboard-layout";

import "./notFoundPage.scss"

export default function NotFoundPage(){
    return(
  
    <DashboardLayout>
       <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
    </DashboardLayout>
    )
}