import { NavLink } from "react-router-dom";
import { Icon } from "@/components";
import "./sideBar.scss"

export default function SideBar() {
    return (
        <div className="navbar">
            <div className="logo">
                <Icon name="logo" />
            </div>

            <nav className="navs">

                <div>
                    <NavLink
                        className={({ isActive }) => `link ${isActive ? "active-link" : ""}`}
                        to="/dashboard/comingsoon"
                    >
                        <div className="icon-and-text">
                            <Icon name="home" />
                            <div>Home</div>
                        </div>
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        className={({ isActive }) => `link ${isActive ? "active-link" : ""}`}

                        to="/dashboard/partners"
                    >
                        <div className="icon-and-text">
                            <Icon name="partners" />
                            <div>Partners</div>
                        </div>
                        
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        className={({ isActive }) => `link ${isActive ? "active-link" : ""}`}

                        to="/dashboard/activity"
                    >
                        <div className="icon-and-text">
                            <Icon name="activity" />
                            <div>Activity</div>
                        </div>
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        className={({ isActive }) => `link ${isActive ? "active-link" : ""}`}

                        to="/dashboard/teachers"
                    >
                         <div className="icon-and-text">
                            <Icon name="teacher" />
                            <div>Teacher</div>
                        </div>
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        className={({ isActive }) => `link ${isActive ? "active-link" : ""}`}

                        to="/dashboard/profile"
                    >
                        <div className="icon-and-text">
                            <Icon name="profile" />
                            <div>Profile</div>
                        </div>
                    </NavLink>
                </div>
            </nav>
        </div>
    )
}