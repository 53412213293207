import DashboardLayout from "@/layouts/dashboard-layout";

import "./comingSoon.scss"

export default function ComingSoon (){
    return(
       <DashboardLayout>
        <div className="coming-soon">
            <h1>
                Coming soon . . .
            </h1>
            <p>We are working to bring you necessary profiles for this page. Stay tuned!</p>
        </div>
       </DashboardLayout>
    )
}