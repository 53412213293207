import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SignIn, Home, Activties, Activity } from "@/pages";
import ComingSoon from "@/pages/coming-soon/ComingSoon";
import NotFoundPage from "@/pages/not-found-page/NotFoundPage";

export const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/dashboard/activity" element={<Activties />} />
        <Route path="/dashboard/activity/:activity_id" element={<Activity />} />

        <Route path="/dashboard/comingsoon" element={<ComingSoon />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};
